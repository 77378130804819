// extracted by mini-css-extract-plugin
export var ReactDevBtn = "MeetDevelopers-module--ReactDevBtn--5d731";
export var allSkills = "MeetDevelopers-module--allSkills--4ced2";
export var card = "MeetDevelopers-module--card--30e39";
export var card2 = "MeetDevelopers-module--card2--8e2b2";
export var card3 = "MeetDevelopers-module--card3--a6b29";
export var cardWrapper = "MeetDevelopers-module--cardWrapper--78ed2";
export var description = "MeetDevelopers-module--description--5504c";
export var fifthDev2 = "MeetDevelopers-module--fifthDev2--0e560";
export var firstDev2 = "MeetDevelopers-module--firstDev2--ab1c9";
export var fourthDev2 = "MeetDevelopers-module--fourthDev2--699f9";
export var heading = "MeetDevelopers-module--heading--bba07";
export var meetDevMainWork = "MeetDevelopers-module--meetDevMainWork--14f02";
export var mernIcon = "MeetDevelopers-module--mernIcon--c160d";
export var reactBtn = "MeetDevelopers-module--reactBtn--1488a";
export var secondDev2 = "MeetDevelopers-module--secondDev2--a8c0a";
export var sixthDev2 = "MeetDevelopers-module--sixthDev2--41e04";
export var skill1 = "MeetDevelopers-module--skill1--4b8bf";
export var skill2 = "MeetDevelopers-module--skill2--71777";
export var skill3 = "MeetDevelopers-module--skill3--30255";
export var skill4 = "MeetDevelopers-module--skill4--b654a";
export var skill5 = "MeetDevelopers-module--skill5--e3322";
export var skills = "MeetDevelopers-module--skills--08e4c";
export var thirdDev2 = "MeetDevelopers-module--thirdDev2--c4efa";