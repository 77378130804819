import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import arrow from "../../images/mobile-app-developmemt/arrow.svg"
import "./Discover.scss"
import * as styles from "./Discover.module.scss"
const Discover = ({ strapiData }) => {
  return (
    <div className={`${styles.reactTabs} react`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.Android}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Fintech">
            <Row>
              <Col lg={7}>
                <Nav variant="pills" className={`reactNav`}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div className={styles.tabImg}>
                          <lottie-player
                            autoplay
                            loop
                            src={e?.image1[0]?.localFile?.publicURL}
                            style={{
                              height: "60px",
                              width: "60px",
                              margin: "0px",
                            }}
                          ></lottie-player>
                          <Nav.Link eventKey={`${e?.title}`}>
                            {e?.title}
                          </Nav.Link>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </Col>
              <Col lg={5}>
                <div className="tabs">
                  <Tab.Content className="reactTab">
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Tab.Pane eventKey={`${e?.title}`} key={i}>
                          <div className={styles.boxContent}>
                            <div>
                              <div className="d-flex justify-content-between">
                                <lottie-player
                                  autoplay
                                  loop
                                  src={e?.image1[0]?.localFile?.publicURL}
                                  style={{
                                    height: "60px",
                                    width: "60px",
                                    margin: "0px",
                                  }}
                                ></lottie-player>
                                <div className={`  ${styles.arrowDiv} `}>
                                  {e?.buttons[0]?.url && (
                                    <Link to={e?.buttons[0]?.url}>
                                      <img
                                        className={styles.arrowImg}
                                        src={arrow}
                                        decoding="async"
                                        loading="lazy"
                                        alt="arrow"
                                      />
                                    </Link>
                                  )}
                                </div>
                              </div>
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              ></p>
                            </div>
                          </div>
                        </Tab.Pane>
                      ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default Discover
