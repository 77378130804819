import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TechStacks.module.scss"

const data = [
  {
    title: "Frameworks",
    tech: [
      {
        name: "Django",
      },
      {
        name: "Zope",
      },
      {
        name: "Pyramid",
      },
      {
        name: "Flask",
      },
      {
        name: "Web2py",
      },
      {
        name: "Tronado",
      },
      {
        name: "Bottle",
      },
    ],
  },
  {
    title: "Libraries",
    tech: [
      {
        name: "Fabric",
      },
      {
        name: "Requests",
      },
      {
        name: "Pika",
      },
      {
        name: "Gdata",
      },
      {
        name: "Urlib2",
      },
      {
        name: "Pillow/PIL",
      },
      {
        name: "wxPython",
      },
      {
        name: "Scrapy",
      },
      {
        name: "NetworkX",
      },
    ],
  },
  {
    title: "Tools & Utilities",
    tech: [
      {
        name: "Asyncio",
      },
      {
        name: "NumPy",
      },
      {
        name: "South",
      },
      {
        name: "Beautiful Soap",
      },
      {
        name: "Celery",
      },
      {
        name: "Pycrypto",
      },
      {
        name: "Factory Boy",
      },
      {
        name: "Gunicorn",
      },
      {
        name: "Sqlalchemy",
      },
      {
        name: "Virtualenv",
      },
      {
        name: "Memcached",
      },
    ],
  },
  {
    title: "Tools For PM",
    tech: [
      {
        name: "Jira",
      },
      {
        name: "Trello",
      },
      {
        name: "Slack",
      },
      {
        name: "Zoom",
      },
      {
        name: "Google Meet",
      },
    ],
  },
  {
    title: "Databases",
    tech: [
      {
        name: "MySQL",
      },
      {
        name: "Oracle",
      },
      {
        name: "SQLite",
      },
      {
        name: "MS SQL",
      },
      {
        name: "Mongo DB",
      },
      {
        name: "Postgres",
      },
      {
        name: "Redis",
      },
    ],
  },
]

const TechStacks = ({ strapiData }) => {
  return (
    <div className={`${styles.reactTechStacks}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        {data &&
          data?.map((e, i) => (
            <div className={styles.stacks} key={i}>
              <div
                className={styles.frame}
                dangerouslySetInnerHTML={{
                  __html: e?.title,
                }}
              />
              <div className={styles.tech}>
                {e?.tech &&
                  e?.tech?.map((el, i) => (
                    <h3
                      className={
                        i === 1
                          ? styles.name1
                          : i === 3
                          ? styles.name3
                          : i === 5
                          ? styles.name5
                          : i === 7
                          ? styles.name7
                          : i === 9
                          ? styles.name9
                          : styles.name
                      }
                    >
                      {el?.name}
                    </h3>
                  ))}
              </div>
            </div>
          ))}
      </Container>
    </div>
  )
}

export default TechStacks
