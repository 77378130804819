import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Expertise.module.scss"
const Expertise = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.reactExpertise}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
          <Row className={`gap-30 ${styles.cardWrapper}`}>
            {strapiData?.cards?.map((e, i) => (
              <Col
                lg={4}
                xl={4}
                md={6}
                sm={12}
                xs={12}
                key={i}
                className="gap-30 d-flex"
              >
                <div
                  className={
                    i === 0
                      ? styles.card1
                      : i === 1
                      ? styles.card2
                      : i === 2
                      ? styles.card3
                      : i === 3
                      ? styles.card4
                      : i === 4
                      ? styles.card5
                      : i === 5
                      ? styles.card6
                      : i === 6
                      ? styles.card7
                      : i === 7
                      ? styles.card8
                      : i === 8
                      ? styles.card9
                      : ""
                  }
                >
                  <div className="d-flex align-items-center">
                    <div>
                      <lottie-player
                        autoplay
                        loop
                        src={e?.image1[0]?.localFile?.publicURL}
                        style={{ height: "60px", width: "60px", margin: "0px" }}
                      ></lottie-player>
                    </div>
                    <div>
                      <h3>{e?.title}</h3>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Expertise
