import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import LeftArrow from "../../images/softwere-development/left.svg"
import ratingStars from "../../images/softwere-development/rating-stars.png"
import RightArrow from "../../images/softwere-development/right.svg"
import tesDots from "../../images/tesDots.png"
import tesRec from "../../images/tesRec.png"
import circleTes from "../../images/circleTes.png"
import * as styles from "./Testimonials2.module.scss"

const Testimonials2 = ({ strapiData }) => {
  function Arrow(props) {
    const { onClick } = props
    return (
      <div className={styles.iconContainer}>
        <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
          <img
            src={RightArrow}
            className={styles.portfolioArrowIcon}
            decoding="async"
            loading="lazy"
            alt="arrow"
          />
        </div>
      </div>
    )
  }
  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.iconContainerLeft}>
        <div className={styles.portfolioArrowIconCover} onClick={onClick}>
          <img
            src={LeftArrow}
            className={styles.portfolioArrowIcon}
            onClick={onClick}
            decoding="async"
            loading="lazy"
            alt="arrow"
          />
        </div>
      </div>
    )
  }
  const settings = {
    autoplay: false,
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: true,
        },
      },
    ],
  }

  return (
    <section className={styles.section}>
      <img
        className={styles.circleTes}
        src={circleTes}
        decoding="async"
        loading="lazy"
        alt="backgrounnd"
      />
      <img
        className={styles.tesRec}
        src={tesRec}
        decoding="async"
        loading="lazy"
        alt="backgrounnd"
      />
      <Container>
        <div className={styles.testimonials}>
          <div>
            <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            <div
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </div>
          <div className="pt-2 pt-md-0">
            <a
              href="https://clutch.co/profile/invozone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className={styles.tesBtn}>
                Trusted by Top Companies
              </Button>
            </a>
          </div>
        </div>
        <Slider {...settings}>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <div key={i}>
                <Row className={styles.row}>
                  <Col md={12} className="mr-3 mr-md-0">
                    <img
                      className={styles.personBg}
                      src={e?.image1[0]?.localFile?.publicURL}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </Col>
                  <Col md={12} className={styles.content}>
                    <div className="mb-3">
                      <img
                        src={ratingStars}
                        alt="rating stars"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                    <div
                      className={styles.des}
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    ></div>
                    <p className={styles.aurther}>{e?.title}</p>
                  </Col>
                </Row>
              </div>
            ))}
        </Slider>
      </Container>
      <img
        className={styles.tesDots}
        src={tesDots}
        decoding="async"
        loading="lazy"
        alt="backgrounnd"
      />
    </section>
  )
}

export default Testimonials2
