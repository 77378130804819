// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--d0508";
export var bannerTitle = "Banner-module--bannerTitle--713ed";
export var days = "Banner-module--days--633af";
export var form = "Banner-module--form--81b33";
export var heading = "Banner-module--heading--0d92c";
export var mainStory = "Banner-module--mainStory--f5b92";
export var mainSuccess = "Banner-module--mainSuccess--924c4";
export var reactBanner = "Banner-module--reactBanner--201f6";
export var successPoint1 = "Banner-module--successPoint1--48330";
export var successPoint2 = "Banner-module--successPoint2--0ede5";
export var successPoint3 = "Banner-module--successPoint3--6e091";
export var successPoint4 = "Banner-module--successPoint4--a8959";