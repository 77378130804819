import React from "react"
import * as styles from "./Explore.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import plus from "../../images/hireReactJs/plus.svg"
const Explore = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.reactExplore}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row
            className={`gap-30 justify-content-center ${styles.cardWrapper}`}
          >
            {strapiData?.cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className={styles.react}>
                <div className={styles.card}>
                  <div className={styles.imgs}>
                    <img
                      className={i === 5 ? styles.reactImage5 : styles.reactImg}
                      src={e?.image1[0]?.localFile?.publicURL}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title + e?.image1[0]?.alternativeText}
                    />
                    <img
                      src={plus}
                      alt="plus icon"
                      className={styles.plusImg}
                      decoding="async"
                      loading="lazy"
                    />
                    <img
                      className={i === 5 ? styles.reactImage5 : styles.reactImg}
                      src={e?.image2[0]?.localFile?.publicURL}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title + e?.image2[0]?.alternativeText}
                    />
                  </div>
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Explore
