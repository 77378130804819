// extracted by mini-css-extract-plugin
export var bannerBtn = "Explore-module--bannerBtn--6743a";
export var benefits = "Explore-module--benefits--55c2b";
export var btn_white2_border = "Explore-module--btn_white2_border--6cfae";
export var card = "Explore-module--card--f55da";
export var cardBg = "Explore-module--cardBg--895ce";
export var cardWrapper = "Explore-module--cardWrapper--1c235";
export var cards2 = "Explore-module--cards2--21b34";
export var description = "Explore-module--description--c9ac7";
export var description2 = "Explore-module--description2--7ce8a";
export var heading = "Explore-module--heading--5b8a6";
export var imgs = "Explore-module--imgs--b1655";
export var plusImg = "Explore-module--plusImg--f08e9";
export var react = "Explore-module--react--c2d44";
export var reactExplore = "Explore-module--reactExplore--70420";
export var reactImage5 = "Explore-module--reactImage5--2faf9";
export var reactImg = "Explore-module--reactImg--7787f";