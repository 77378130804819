// extracted by mini-css-extract-plugin
export var Android = "Discover-module--Android--4d001";
export var arrowDiv = "Discover-module--arrowDiv--f034d";
export var arrowImg = "Discover-module--arrowImg--32ca4";
export var boxContent = "Discover-module--boxContent--8e954";
export var cards = "Discover-module--cards--5f44a";
export var cardsContent = "Discover-module--cardsContent--ccf36";
export var cardsZ = "Discover-module--cardsZ--02dfc";
export var description = "Discover-module--description--49733";
export var djangoArrowImg = "Discover-module--djangoArrowImg--d8669";
export var estabBtn = "Discover-module--estabBtn--4a869";
export var explorrBtn = "Discover-module--explorrBtn--17f4b";
export var finImg = "Discover-module--finImg--bdabc";
export var heading = "Discover-module--heading--6e62e";
export var reactTabs = "Discover-module--reactTabs--70e19";
export var tabImg = "Discover-module--tabImg--9b84e";
export var tabbingCards = "Discover-module--tabbingCards--f670f";