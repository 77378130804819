// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--7a7f4";
export var circleTes = "Testimonials2-module--circleTes--e3060";
export var content = "Testimonials2-module--content--3ca12";
export var des = "Testimonials2-module--des--3e96b";
export var iconContainer = "Testimonials2-module--iconContainer--15924";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--e4650";
export var personBg = "Testimonials2-module--personBg--752a8";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--c0a6a";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--751e4";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--58f3c";
export var row = "Testimonials2-module--row--695b5";
export var section = "Testimonials2-module--section--5c693";
export var tesBtn = "Testimonials2-module--tesBtn--91601";
export var tesDots = "Testimonials2-module--tesDots--90c94";
export var tesRec = "Testimonials2-module--tesRec--4fcb0";
export var testimonials = "Testimonials2-module--testimonials--f7551";