import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import zohaib from "../../images/hireReactJs/zohaib-javed.png"
import hamza from "../../images/hireReactJs/hamza-sajid.png"
import zeeshan from "../../images/hireReactJs/zeeshan-khan.png"
import * as styles from "./MeetDevelopers.module.scss"
const MeetDevelopers = ({ strapiData }) => {
  const dataDev1 = [
    {
      skill: "Front-end Full-stack Developer",
    },
    {
      skill: "HTML5",
    },
    {
      skill: "REST APIs",
    },
    {
      skill: "Node.js",
    },
    {
      skill: "ReactD3.js",
    },
  ]
  const dataDev2 = [
    {
      skill: "ReactJs",
    },
    {
      skill: "JavaScript",
    },
    {
      skill: "React Native",
    },
    {
      skill: "TypeScript",
    },
    {
      skill: "Node.Js",
    },
    {
      skill: "MySQL",
    },
  ]
  const dataDev3 = [
    {
      skill: "ReactJS",
    },
    {
      skill: "Angular JS",
    },
    {
      skill: "ReactNative",
    },
    {
      skill: "PostgreSQL",
    },
    {
      skill: "HTML/CSS",
    },
    {
      skill: "Nod.JS",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.meetDevMainWork}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col lg={4} md={6} sm={12} xs={12} key={i}>
                  <div
                    className={
                      i === 1
                        ? styles.card2
                        : i === 2
                        ? styles.card3
                        : styles.card
                    }
                  >
                    <img
                      src={
                        i === 0
                          ? zohaib
                          : i === 1
                          ? hamza
                          : i === 2
                          ? zeeshan
                          : ""
                      }
                      alt=""
                    />
                    <h3>{e?.title}</h3>
                    <div className={styles.allSkills}>
                      {i === 0
                        ? dataDev1 &&
                          dataDev1?.map((s, e) => (
                            <div className={styles.skills} key={e}>
                              <div
                                className={
                                  e === 0
                                    ? styles.skill1
                                    : e === 1
                                    ? styles.skill2
                                    : e === 2
                                    ? styles.skill3
                                    : e === 3
                                    ? styles.skill4
                                    : e === 4
                                    ? styles.skill5
                                    : ""
                                }
                              >
                                {s?.skill}
                              </div>
                            </div>
                          ))
                        : i === 1
                        ? dataDev2 &&
                          dataDev2.map((sl, el) => (
                            <div className={styles.skills} key={el}>
                              <div
                                className={
                                  el === 0
                                    ? styles.firstDev2
                                    : el === 1
                                    ? styles.secondDev2
                                    : el === 2
                                    ? styles.thirdDev2
                                    : el === 3
                                    ? styles.fourthDev2
                                    : el === 4
                                    ? styles.fifthDev2
                                    : el === 5
                                    ? styles.sixthDev2
                                    : ""
                                }
                              >
                                {sl?.skill}
                              </div>
                            </div>
                          ))
                        : i === 2
                        ? dataDev3 &&
                          dataDev3.map((al, ei) => (
                            <div className={styles.skills} key={ei}>
                              <div
                                className={
                                  ei === 0
                                    ? styles.firstDev2
                                    : ei === 1
                                    ? styles.secondDev2
                                    : ei === 2
                                    ? styles.thirdDev2
                                    : ei === 3
                                    ? styles.fourthDev2
                                    : ei === 4
                                    ? styles.fifthDev2
                                    : ei === 5
                                    ? styles.sixthDev2
                                    : ""
                                }
                              >
                                {al?.skill}
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                    <div className={styles.ReactDevBtn}>
                      <Link to="#reactform">
                        <Button className={styles.reactBtn}>
                          {i === 0
                            ? "Talk To Zohaib"
                            : i === 1
                            ? "Talk To Hamza"
                            : "Talk To Zeeshan"}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MeetDevelopers
