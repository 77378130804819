import React, { useEffect, useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import activeIcon from "../../images/hireReactJs/active.svg"
import nonactive from "../../images/hireReactJs/nonactive.svg"
import * as styles from "./HowHire.module.scss"
import "./HowHire.scss"
import "./HowprogressSlider.scss"

const ContextAwareToggle = ({ children, eventKey, callback, ind }) => {
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = ind === eventKey

  return (
    <Accordion.Toggle
      className={isCurrentEventKey ? styles.headr : ""}
      as={Card.Header}
      onClick={decoratedOnClick}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const HowHire = ({ strapiData }) => {
  const [imageInd, setImageInd] = useState(0)
  const [current, setCurrent] = useState(0)
  const [progress, setProgress] = useState(0)

  const nextSlide = currentIndex =>
    (currentIndex + 1) % strapiData?.cards?.length

  useEffect(() => {
    const interval = setInterval(() => {
      setImageInd(nextSlide(imageInd))
      setCurrent(nextSlide(current))
    }, 10000)

    return () => clearInterval(interval)
  }, [current, imageInd, strapiData?.cards])

  const handleStepClick = index => {
    setImageInd(index)
    setCurrent(index)
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(progress => {
        if (current !== imageInd) {
          const elementWidth = window.getComputedStyle(sliderProgress[0]).height
          // console.log("imageIndex", imageInd, current, elementWidth)
          if (parseFloat(elementWidth) > 0)
            sliderProgress[0].style.height = `0%`

          setImageInd(current)
          setProgress(0)
        }
        const nextProgress = progress + 1
        const sliderProgress = document.querySelectorAll(`.isActivee`)
        if (imageInd !== 4) sliderProgress[0].style.height = `${nextProgress}%`
        // console.log(sliderProgress)

        if (imageInd === 4 && nextProgress > 80) {
          setProgress(0)
          setImageInd(0)
          setCurrent(0)

          return 0
        }
        if (nextProgress > 100) {
          sliderProgress[0].style.height = `0%`

          if (imageInd === 4) {
            setProgress(0)
            setImageInd(0)
            setCurrent(0)
          } else {
            const nextSlide = () => {
              const nextIndex = imageInd + 1
              return nextIndex >= 5 ? 0 : nextIndex
            }
            setProgress(0)
            setImageInd(nextSlide())
            setCurrent(nextSlide())
          }

          return 0
        }
        return nextProgress
      })
    }, 50)

    return () => clearInterval(interval)
  }, [progress, current, imageInd])

  return (
    <div className={styles.reactSection}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className="align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div
              className={styles.description2}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className="faqsAccordian reactEasySteps d-flex">
              <div
                className={
                  imageInd === 1
                    ? styles.dottedLine2
                    : imageInd === 2
                    ? styles.dottedLine3
                    : imageInd === 3
                    ? styles.dottedLine4
                    : imageInd === 4
                    ? styles.dottedline5
                    : styles.dottedLine
                }
              ></div>
              <Accordion defaultActiveKey={`ek-${imageInd}`}>
                {strapiData?.cards?.map((el, i) => (
                  <Card
                    key={i}
                    //border="light"
                    className={`${"custom-border"}`}
                    style={{ overflow: "visible" }}
                    onClick={() => handleStepClick(i)}
                  >
                    <ContextAwareToggle
                      eventKey={`ek-${i}`}
                      ind={`ek-${imageInd}`}
                    >
                      <div className={styles.faqsNum}>
                        <div
                          className={
                            i === imageInd
                              ? styles[`activeIconStep${i + 1}`]
                              : styles[`nonactiveIconStep${i + 1}`]
                          }
                        >
                          <img
                            src={i === imageInd ? activeIcon : nonactive}
                            alt={el?.title}
                            decoding="async"
                            loading="lazy"
                          />
                        </div>
                        {imageInd === i && (
                          <div>
                            <span className={styles.serial2}>
                              Step 0{+(i + 1)}
                            </span>
                            <span className={styles.serial}>
                              Step 0{+(i + 1)}
                            </span>
                          </div>
                        )}
                        <h3
                          className={`${styles.faqsSub} py-2 mb-0`}
                          type="button"
                        >
                          {el?.title}
                        </h3>
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse
                      className={`${imageInd === i && "show"}`}
                    >
                      <Card.Body className={styles.textColor}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el?.subTitle,
                          }}
                        />
                        <div
                          className={`progress-barr`}
                          id={`sliderProgress-${i}`}
                        >
                          <span
                            className={`${imageInd === i && "isActivee"}`}
                          ></span>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.crossImg}>
              <img
                src={
                  strapiData?.cards[imageInd]?.image1 &&
                  strapiData?.cards[imageInd]?.image1[0]?.localFile?.publicURL
                }
                decoding="async"
                loading="lazy"
                alt={strapiData?.cards[imageInd]?.title + " img"}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HowHire
