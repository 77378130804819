import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import tick from "../../images/DJango/tick.svg"
import * as styles from "./Banner.module.scss"
import FaqForm from "../common/hireDeveloperForm"

const Banner = ({ strapiData, pageName }) => {
  return (
    <div id="reactBanner" className={styles.reactBanner}>
      <Container>
        <Row>
          <Col xl={6} md={12} lg={6}>
            <div className={styles.heading}>
              <h1 className={styles.bannerHeading}>Hire ReactJS Developers</h1>
              <p className={styles.days}>Within 2 Days</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={styles.mainStory}>
              {strapiData?.cards?.map((el, i) => (
                <div className={styles.mainSuccess} key={i}>
                  <div
                    className={
                      i === 0
                        ? styles.successPoint1
                        : i === 1
                        ? styles.successPoint2
                        : i === 2
                        ? styles.successPoint3
                        : i === 3
                        ? styles.successPoint4
                        : ""
                    }
                  >
                    <div>
                      <img
                        src={tick}
                        alt="tick"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.bannerTitle}>{el?.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col id="reactform" xl={6} md={12} lg={6} className={styles.form}>
            <FaqForm pageName={pageName} reactForm={true} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
