import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Banner from "../components/react-js/Banner"
import MainLayout from "../layouts/MainLayout"
import WhyHire from "../components/react-js/WhyHire"
import WhyHireMobile from "../components/react-js/WhyHireMobile"
import MeetDevelopers from "../components/react-js/MeetDevelopers"
import DynamicModels from "../components/react-js/DynamicModels"
import TechStacks from "../components/react-js/TechStacks"
import Hunt from "../components/react-js/Hunt"
import Discover from "../components/react-js/Discover"
import Testimonials from "../components/react-js/Testimonials"
import Testimonials2 from "../components/react-js/Testimonials2"
import Explore from "../components/react-js/Explore"
import Expertise from "../components/react-js/Expertise"
import HowHire from "../components/react-js/HowHire"
import HowHireMobile from "../components/react-js/HowHireMobile"
import DiscoverMobile from "../components/react-js/DiscoverMobile"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const reactJs = ({ data }) => {
  //console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const why = data?.strapiPage?.sections[1]
  const meetDevelopers = data?.strapiPage?.sections[2]
  const howHire = data?.strapiPage?.sections[3]
  const expertiseReact = data?.strapiPage?.sections[4]
  const dynamic = data?.strapiPage?.sections[5]
  const explore = data?.strapiPage?.sections[6]
  const discover = data?.strapiPage?.sections[7]
  const techStacks = data?.strapiPage?.sections[8]
  const hunt = data?.strapiPage?.sections[9]
  const portfolio = data?.strapiPage?.sections[10]
  const clients = data?.strapiPage?.sections[11]
  const faqs = data?.strapiPage?.sections[12]
  const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire React Js Developer"
      />
      {!isMobile && !isTablet ? (
        <WhyHire strapiData={why} />
      ) : (
        <WhyHireMobile strapiData={why} />
      )}
      <MeetDevelopers strapiData={meetDevelopers} />
      {!isMobile && !isTablet ? (
        <HowHire strapiData={howHire} />
      ) : (
        <HowHireMobile strapiData={howHire} />
      )}

      <Expertise strapiData={expertiseReact} />
      <DynamicModels strapiData={dynamic} />
      <Explore strapiData={explore} />
      {!isMobile && !isTablet ? (
        <Discover strapiData={discover} />
      ) : (
        <DiscoverMobile strapiData={discover} />
      )}

      <TechStacks strapiData={techStacks} />
      <Hunt strapiData={hunt} />
      <WorkPortfolio strapiData={portfolio} />

      {!isMobile && !isTablet ? (
        <Testimonials
          strapiData={testimonials}
          testiHead={"What Our Clients Say"}
          testiSubtitle={
            "Read the feedback from our partners about the services we provide at InvoZone."
          }
        />
      ) : (
        <Testimonials2
          strapiData={testimonials}
          testiHead={"What Our Clients Say"}
          testiSubtitle={
            "Read the feedback from our partners about the services we provide at InvoZone."
          }
        />
      )}
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "react-js" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default reactJs
