// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--ffc69";
export var clientDots2 = "Testimonials-module--clientDots2--7701c";
export var content = "Testimonials-module--content--54df2";
export var dots3 = "Testimonials-module--dots3--aa070";
export var hireArrow = "Testimonials-module--hireArrow--4b4aa";
export var iconContainer = "Testimonials-module--iconContainer--d1bfd";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--38d84";
export var imgBtn = "Testimonials-module--imgBtn--bf559";
export var person = "Testimonials-module--person--252a7";
export var personBg = "Testimonials-module--personBg--ac674";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--23336";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--b0c9f";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--ab6ba";
export var row = "Testimonials-module--row--d6644";
export var section = "Testimonials-module--section--b02ea";
export var staffRec = "Testimonials-module--staffRec--d4036";
export var testName = "Testimonials-module--testName--b751e";
export var testimonials = "Testimonials-module--testimonials--9d57a";
export var testimonialsContent = "Testimonials-module--testimonialsContent--902ce";
export var vector2 = "Testimonials-module--vector2--1999b";