// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyHire-module--SliderWidth--a29f7";
export var arrowImg = "WhyHire-module--arrowImg--e6ef9";
export var card = "WhyHire-module--card--04807";
export var cardImg = "WhyHire-module--cardImg--80fef";
export var cardMian = "WhyHire-module--cardMian--e7969";
export var cardWrapper = "WhyHire-module--cardWrapper--3e546";
export var cards = "WhyHire-module--cards--44775";
export var description = "WhyHire-module--description--aca5c";
export var heading = "WhyHire-module--heading--27473";
export var headingPremium = "WhyHire-module--headingPremium--5bf05";
export var mainWorkReactJs = "WhyHire-module--mainWorkReactJs--2b84c";
export var portfolioArrowIcon = "WhyHire-module--portfolioArrowIcon--0f3ad";
export var portfolioArrowIconCover = "WhyHire-module--portfolioArrowIconCover--d4b45";
export var portfolioArrowRightIconCover = "WhyHire-module--portfolioArrowRightIconCover--f530e";
export var premiumImg = "WhyHire-module--premiumImg--3fcf4";
export var providingImg = "WhyHire-module--providingImg--556a8";
export var reactJsIconContainer = "WhyHire-module--reactJsIconContainer--76385";
export var reactJsIconContainerLeft = "WhyHire-module--reactJsIconContainerLeft--9c0c8";
export var teamButton = "WhyHire-module--teamButton--d4c09";
export var trailBg = "WhyHire-module--trailBg--c3814";