// extracted by mini-css-extract-plugin
export var activeDottedLine = "HowHire-module--activeDottedLine--56fac";
export var activeIconStep1 = "HowHire-module--activeIconStep1--387aa";
export var activeIconStep2 = "HowHire-module--activeIconStep2--dffc5";
export var activeIconStep3 = "HowHire-module--activeIconStep3--e2a9f";
export var activeIconStep4 = "HowHire-module--activeIconStep4--b5932";
export var activeIconStep5 = "HowHire-module--activeIconStep5--ecf38";
export var blueDottedLine = "HowHire-module--blueDottedLine--99486";
export var blueDottedLine2 = "HowHire-module--blueDottedLine2--dfaba";
export var blueDottedLine3 = "HowHire-module--blueDottedLine3--1ef20";
export var blueDottedLine4 = "HowHire-module--blueDottedLine4--a52f4";
export var crossImg = "HowHire-module--crossImg--3adac";
export var description = "HowHire-module--description--12fac";
export var description2 = "HowHire-module--description2--d2ab2";
export var dottedLine = "HowHire-module--dottedLine--652b7";
export var dottedLine2 = "HowHire-module--dottedLine2--6b094";
export var dottedLine3 = "HowHire-module--dottedLine3--67346";
export var dottedLine4 = "HowHire-module--dottedLine4--8149d";
export var dottedline5 = "HowHire-module--dottedline5--0dd78";
export var faqButton = "HowHire-module--faqButton--ce343";
export var faqsNum = "HowHire-module--faqsNum--a8404";
export var faqsSub = "HowHire-module--faqsSub--2f579";
export var heading = "HowHire-module--heading--e8b0c";
export var headr = "HowHire-module--headr--a4a44";
export var nonactiveIconStep1 = "HowHire-module--nonactiveIconStep1--29d0c";
export var nonactiveIconStep2 = "HowHire-module--nonactiveIconStep2--4b80a";
export var nonactiveIconStep3 = "HowHire-module--nonactiveIconStep3--91df2";
export var nonactiveIconStep4 = "HowHire-module--nonactiveIconStep4--fc386";
export var nonactiveIconStep5 = "HowHire-module--nonactiveIconStep5--3e19c";
export var quesColor = "HowHire-module--quesColor--afcc4";
export var quesColorSelected = "HowHire-module--quesColorSelected--5b9e2";
export var reactSection = "HowHire-module--reactSection--8bd97";
export var reverse = "HowHire-module--reverse--d82fc";
export var serial = "HowHire-module--serial--5ac5e";
export var serial2 = "HowHire-module--serial2--e3403";
export var textColor = "HowHire-module--textColor--03766";