// extracted by mini-css-extract-plugin
export var card1 = "Expertise-module--card1--82754";
export var card2 = "Expertise-module--card2--73e72";
export var card3 = "Expertise-module--card3--9158b";
export var card4 = "Expertise-module--card4--9bb9c";
export var card5 = "Expertise-module--card5--3def0";
export var card6 = "Expertise-module--card6--2b6f2";
export var card7 = "Expertise-module--card7--4f355";
export var card8 = "Expertise-module--card8--16d81";
export var card9 = "Expertise-module--card9--2a16b";
export var cardWrapper = "Expertise-module--cardWrapper--0f6b2";
export var description = "Expertise-module--description--4b073";
export var heading = "Expertise-module--heading--937b0";
export var reactExpertise = "Expertise-module--reactExpertise--a80dc";