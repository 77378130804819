// extracted by mini-css-extract-plugin
export var crossImg = "HowHireMobile-module--crossImg--907e7";
export var description = "HowHireMobile-module--description--0400b";
export var description2 = "HowHireMobile-module--description2--3e413";
export var faqButton = "HowHireMobile-module--faqButton--e4837";
export var faqsNum = "HowHireMobile-module--faqsNum--07817";
export var faqsSub = "HowHireMobile-module--faqsSub--99e8d";
export var head = "HowHireMobile-module--head--4adba";
export var headr = "HowHireMobile-module--headr--5cc9d";
export var quesColor = "HowHireMobile-module--quesColor--65828";
export var quesColorSelected = "HowHireMobile-module--quesColorSelected--392ce";
export var reactMobilesection = "HowHireMobile-module--reactMobilesection--2ae13";
export var reverse = "HowHireMobile-module--reverse--dd153";
export var serial = "HowHireMobile-module--serial--1ddc7";
export var serial2 = "HowHireMobile-module--serial2--7cc21";
export var textColor = "HowHireMobile-module--textColor--cc3d0";