// extracted by mini-css-extract-plugin
export var description = "TechStacks-module--description--12a99";
export var frame = "TechStacks-module--frame--11ec6";
export var heading = "TechStacks-module--heading--5c4d3";
export var name = "TechStacks-module--name--65e63";
export var name1 = "TechStacks-module--name1--2360c";
export var name3 = "TechStacks-module--name3--86bff";
export var name5 = "TechStacks-module--name5--fba0d";
export var name7 = "TechStacks-module--name7--b89ae";
export var name9 = "TechStacks-module--name9--acff0";
export var reactTechStacks = "TechStacks-module--reactTechStacks--e6487";
export var stacks = "TechStacks-module--stacks--cbcf2";
export var tech = "TechStacks-module--tech--37b7b";